<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :fullscreen="openInFullscreen"
    :append-to-body="appendToBody"
    @close="handleClose"
  >
    <div ref="dialog"></div>
    <crud-editor
      v-if="dialogVisible"
      ref="editor"
      :is-embedded="true"
      :disabled="disabled"
      :item-type="entity"
      save-button-position="bottom"
      v-bind="{ ...$attrs, ...editorProps }"
      v-on="$listeners"
    >
      <template #footer="{ loading }">
        <slot v-bind="{ loading }" />
      </template>
    </crud-editor>
  </el-dialog>
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
export default {
  components: {
    CrudEditor
  },
  props: {
    visible: Boolean,
    entity: {
      type: String
    },
    title: {
      type: String,
      default: ''
    },
    openInFullscreen: {
      type: Boolean,
      default: false
    },
    editorProps: {
      type: Object,
      default: () => ({})
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: this.visible
    };
  },
  watch: {
    visible(visible) {
      this.dialogVisible = visible;
    },
    dialogVisible(dialogVisible) {
      this.$emit('update:visible', dialogVisible);
    }
  },
  methods: {
    clearForm() {
      if (this.$refs.editor) {
        this.$refs.editor.clearForm();
      }
    },
    saveForm() {
      if (this.$refs.editor) {
        this.$refs.editor.handleSave();
      }
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped></style>
