<template>
  <div class="app-container">
    <CrudTable
      ref="table"
      entity="Eventlog"
      :columns="columns"
      use-pagination
      :allow-create="false"
      :on-row-dbl-click="showEdit"
      :on-edit="showEdit"
      :generate-summary="generateSummary"
      :allow-delete="false"
      show-edit-as-view-y-n
      :is-disabled-row="row => popupActiveYN"
    />
    <dialog-editor
      ref="dialog"
      class="dialog-container evenlog-container"
      :visible.sync="dialogVisible"
      v-bind="editorProps"
      prevent-default
      @saved="handleSaved"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import crudIndexMixin from '@/mixins/crud-index';
import DialogEditor from '@/components/crud/DialogEditor';
import { getFormDefinition } from './form';

function errorMessage(row, column, cellValue) {
  const paragraphs = row.message.split('\n');
  let currentParagraph;
  while ((currentParagraph = paragraphs.shift())) {
    if (currentParagraph) {
      return currentParagraph + (paragraphs.length > 0 ? ' ...' : '');
    }
  }
  return row.message;
}

function levelFilter(level) {
  const levelMap = {
    Trace: 'info',
    Debug: 'info',
    Error: 'danger',
    Fatal: 'danger',
    Info: 'success',
    Warn: 'warning'
  };
  return levelMap[level];
}

const levelOptions = [
  { value: 'Trace', label: 'Trace' },
  { value: 'Debug', label: 'Debug' },
  { value: 'Error', label: 'Error' },
  { value: 'Fatal', label: 'Fatal' },
  { value: 'Info', label: 'Info' },
  { value: 'Warn', label: 'Warn' }
];

export default {
  name: 'EventLogList',
  components: { CrudTable, DialogEditor },
  mixins: [crudIndexMixin],
  data() {
    return {
      dialogVisible: false,
      editorProps: {},
      columns: [
        {
          field: 'occurredAt',
          label: 'eventlog.occurredAt',
          width: 170,
          type: 'dateTime',
          format: 'dd-MM-yyyy HH:mm:ss'
        },
        {
          field: 'level',
          label: 'eventlog.level',
          width: 80,
          type: 'tag',
          tagFormatter: levelFilter,
          filter: {
            type: 'select',
            options: levelOptions,
            valueField: 'value',
            labelField: 'label'
          },
          multiple: true,
          sortable: false
        },
        { field: 'logger', label: 'eventlog.logger', width: 300, showOverflowTooltip: true },
        { field: 'message', label: 'eventlog.message', showOverflowTooltip: true, formatter: errorMessage }
      ]
    };
  },
  methods: {
    generateSummary() {
      return `item`;
    },
    showEdit(row) {
      if (this.disabled) {
        return false;
      }
      this.editorProps = {
        id: row.id,
        isEdit: true,
        definition: getFormDefinition(this),
        fetchById: row ? () => Promise.resolve(row) : undefined
      };
      this.dialogVisible = true;
    },
    handleSaved(row) {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.evenlog-container {
  .dse-text-area {
    .el-textarea.is-disabled {
      .el-textarea__inner {
        color: #080808;
        background: #fcfcfc;
        cursor: inherit;
      }
    }
  }
}
</style>
